import React, { useEffect, useContext } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "./TopBar";
import CookieConsent from "react-cookie-consent";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    // minHeight: "calc(100vh - 415px)",
  },

  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  root1: {
    background: "#1A2C38",
    overflow: "hidden",
    position: "relative",
    height: "100dvh",
  },
  MainLayout: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
    overflowY: "auto",
  },
  contentContainer1: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
    // backgroundImage: "url(./images/mainbg.png)",
    backgroundSize: "cover",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  console.log(history.location);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      {/* <Box className="orangeshadeBox1"></Box> */}
      {/* <Box className="purpleBox1"></Box> */}
      <TopBar />
      {/* <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div> */}
      <div className={classes.contentContainer1}>

        <div className={classes.MainLayout}>{children}</div>
      </div>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="Accept All Cookies"
        declineButtonText="Reject All"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#0F212E", zIndex: "9999" }}
        declineButtonStyle={{
          background: "rgb(79, 79, 79)",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
        }}
        buttonStyle={{
          background: "#E59446 !important",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
        }}
        expires={150}
      >
        <Typography variant="body1" style={{ fontSize: "12px" }}>
          By clicking "Accept All Cookies", you agree to the store the cookies on your device for enhancing site navigation, analyzing site usage, and assisting in our marketing efforts.&nbsp;
          {/* <Link
            component={RouterLink}
            to="/cookies-policy"

            style={{ color: "#E59446", cursor: "pointer" }}
            className={classes.link}
          >
            More information
          </Link> */}
        </Typography>
      </CookieConsent>
    </div>
  );
};

export default MainLayout;
